import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import Andre from "../images/photos/andre.jpg"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/4.png"

const LECTURER = [
  {
    src: Andre,
    alt: "",
    personName: "André Heller",
    linkedInProfile: "//linkedin.com/in/andheller",
    text: [
      "André je Google trenérem pro Google Analytics. Má za sebou jedny z největších implementací GA4 v ČR – Českou televizi, Heuréku, CNC. Se svými přednáškami opakovaně vyhrává cenu za nejlepší session na MeasureCampu Czechia.",
    ],
  },
]

const JakNaDokumentaci: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Dokumentace" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Dokumentace"
        numberOfLesson="4/13"
      ></SyllabusHeader>
      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>zadávací dokumentace – pohled vývojáře</div>
        <div>konverze business zadání – pohled businessu</div>
        <div>konkrétní popis dat a jejich nastavení – pohled analytika</div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default JakNaDokumentaci
